<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/planner/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom Planner" />

        <page-section class="py-40px py-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <div class="tit text-center mb-12px mb-md-16px">지금 집에서 온라인으로 욕실 계획을 세우세요</div>
                <div class="d-flex justify-center">
                    <v-btn large color="primary" class="rounded-xs" href="https://badplaner.villeroy-boch.de/3DPlanerWeb/cloud/VB-EUROPE/?ts=1726118325074&vs=1726118325074#intro" target="_blank">
                        <span class="txt txt--xs white--text font-weight-medium">바로 시작하기</span>
                        <v-img max-width="24" src="/images/icon/icon-arrow-right-white.svg" class="ml-8px" />
                    </v-btn>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <router-link to="#1" class="txt txt--sm d-flex align-center">
                                    욕실 플래너 - 가장 중요한 것을 한눈에
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#2" class="txt txt--sm d-flex align-center">
                                    FAQ 및 유용한 팁
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#3" class="txt txt--sm d-flex align-center">
                                    빠른 시작을 위한 전문 템플릿
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#4" class="txt txt--sm d-flex align-center">
                                    욕실 영감 - 새로운 아이디어와 디자인
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <v-icon small>mdi-check</v-icon>
                                </v-col>
                                <v-col>
                                    <div class="txt txt--sm txt--dark font-weight-medium mb-4px">간단하고 유연함</div>
                                    <p class="txt txt--sm">개인의 아이디어에 따라 언제 어디서나 꿈의 욕실을 계획해 보세요.</p>
                                </v-col>
                            </v-row>
                            <v-row class="row--xs pt-12px pt-md-16px">
                                <v-col cols="auto">
                                    <v-icon small>mdi-check</v-icon>
                                </v-col>
                                <v-col>
                                    <div class="txt txt--sm txt--dark font-weight-medium mb-4px">사용자 친화적</div>
                                    <p class="txt txt--sm">직관적인 조작으로 단 몇 단계만으로 빠르고 쉽게 설계할 수 있습니다.</p>
                                </v-col>
                            </v-row>
                            <v-row class="row--xs pt-12px pt-md-16px">
                                <v-col cols="auto">
                                    <v-icon small>mdi-check</v-icon>
                                </v-col>
                                <v-col>
                                    <div class="txt txt--sm txt--dark font-weight-medium mb-4px">3D 시각화</div>
                                    <p class="txt txt--sm">3D 계획 덕분에 미래의 기분 좋은 장소에 대한 현실적인 아이디어를 얻을 수 있습니다.</p>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-40px my-md-80px">
            <v-container>
                <div class="product-section-title">
                    <div class="tit grey lighten-5 font-tertiary">욕실을 올바르게 계획하는 방법</div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">3D 욕실 플래너의 작동 방식은 다음과 같습니다.</div>
                        <p class="txt txt--sm">세련되게 디자인된 욕실은 네 개의 벽 안에 있는 나만의 웰빙 오아시스입니다. 평화롭게 휴식을 취하고 재충전할 수 있는 휴양지입니다. 새 건물이든 개축 건물이든 집에서 향상된 삶의 질을 즐겨보세요. 온라인으로 욕실을 쉽게 계획하는 방법을 영상에서 알아보세요.</p>
                        <v-btn large elevation="2" href="https://youtu.be/5mYSf3E0dg4?si=Q-nDh706gwvW_gmU" target="_blank" color="white" class="rounded-xs mt-18px mt-md-24px">
                            <span class="txt txt--xs txt--dark font-weight-medium">YouTube 비디오 지침 보러 가기</span>
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="7">
                        <video width="100%" controls src="/videos/ideas/bathroom/planner/planner.mp4"></video>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img max-width="470" src="/images/ideas/bathroom/planner/planner-1.webp" class="mx-auto" />
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-expansion-panels tile>
                            <template v-for="item in steps">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <div class="tit text-center font-tertiary">가장 중요한 기능을 한눈에</div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img max-width="470" src="/images/ideas/bathroom/planner/planner-2.webp" class="mx-auto" />
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-expansion-panels tile>
                            <template v-for="item in functions">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        <ul class="function-content-list">
                                            <template v-for="child in item.children">
                                                <li :key="child">
                                                    {{ child }}
                                                </li>
                                            </template>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-img src="/images/ideas/bathroom/planner/planner-full.webp" />
        </page-section>

        <page-section>
            <v-container></v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px mb-20px mb-md-40px" id="2">
            <v-container>
                <div class="product-section-title">
                    <div class="tit grey lighten-5 font-tertiary">욕실 계획에 대한 팁</div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" lg="8">
                        <div class="tit mb-12px mb-md-16px">계획하기 전에 이 점을 고려해야 합니다.</div>
                        <p class="txt txt--sm">새 욕실이 시각적으로나 기능적으로 원하는 모든 것을 충족시키려면 욕실을 계획하기 전에 몇 가지 필수 사항을 고려하고 특정 기본 조건을 명확히 해야 합니다.</p>

                        <v-expansion-panels tile>
                            <template v-for="item in before">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" lg="8">
                        <div class="tit mb-12px mb-md-16px">욕실 계획을 위한 팁과 요령</div>
                        <p class="txt txt--sm">귀하가 꿈꾸는 욕실은 세련된 디자인과 실용적인 유용성이 성공적으로 결합된 욕실이어야 합니다. 계획할 때 염두에 두어야 할 팁과 요령 목록을 정리했습니다.</p>

                        <v-expansion-panels tile>
                            <template v-for="item in tips">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" lg="8">
                        <div class="tit mb-12px mb-md-16px">욕실 플래너 FAQ</div>
                        <p class="txt txt--sm">여기에서는 온라인 욕실 플래너의 취급 및 기능에 관해 자주 묻는 질문에 대한 답변을 찾을 수 있습니다.</p>

                        <v-expansion-panels tile>
                            <template v-for="item in faqs">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px" id="3">
            <v-container>
                <div class="product-section-title">
                    <div class="tit grey lighten-5 font-tertiary">욕실 플래너를 위한 계획 템플릿</div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" lg="8">
                        <p class="txt txt--sm">지금 욕실 플래너에서 엄선된 욕실 전문가가 만든 디자인을 만나보세요. 한 번의 클릭으로 계획 템플릿을 시작한 다음 무료 3D 욕실 플래너를 사용하여 욕실을 개인의 희망과 요구 사항에 빠르고 쉽게 적용할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <planner-slides />

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px" id="4">
            <v-container>
                <div class="product-section-title">
                    <div class="tit grey lighten-5 font-tertiary">당신이 꿈꾸는 욕실을 위한 영감</div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img src="/images/ideas/bathroom/planner/planner-3.webp"></v-img>
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실의 슬로프</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">경사진 천장은 욕실 계획을 흥미진진한 도전으로 바꿔줍니다. 그러나 올바른 공간 컨셉을 사용하면 욕실은 웰빙의 아늑한 오아시스가 될 것입니다!</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/ideas/bathroom/planner/slope">
                            자세히 알아보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap text-center">
                    <div class="tit mb-12px mb-md-16px">개인 웰니스 오아시스</div>
                    <p class="txt txt--sm">귀하의 욕실은 웰빙의 매력적인 장소가 됩니다. 언제든지 일상 생활에서 벗어나 편안한 휴식을 취하세요. 여기에서 나만의 웰빙 욕실을 디자인하는 방법을 알아보세요!</p>
                    <div class="d-flex justify-center mt-12px mt-md-16px">
                        <v-btn large color="primary" class="rounded-xs" to="/ideas/bathroom/planner/wellness">
                            <span class="txt txt--xs white--text font-weight-medium">웰니스 욕실에 대한 추가 정보</span>
                            <v-img max-width="24" src="/images/icon/icon-arrow-right-white.svg" class="ml-8px" />
                        </v-btn>
                    </div>
                </div>
                <v-row justify="space-between" align="center" class="row--lg">
                    <v-col cols="7">
                        <v-img src="/images/ideas/bathroom/planner/planner-4.webp" />
                    </v-col>
                    <v-col cols="4">
                        <v-img src="/images/ideas/bathroom/planner/planner-5.webp" />
                    </v-col>
                </v-row>
                <v-row class="row--lg">
                    <v-col cols="12" md="5">
                        <p class="line-height-2 font-size-12">Antao , Theano 및 Le Valence 컬렉션 등 고품질 소재와 분위기 있는 조명으로 제작된 조화로운 욕실에서 순수한 웰빙을 누리세요 . 독립형 욕조 또는 편안한 레인 샤워를 위한 바닥 샤워 트레이 등 욕실이 웰니스 오아시스가 됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에 대한 아이디어</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">욕실은 더욱 현대적이고 편안해지고 있습니다. 당사 제품군에서는 고품질 욕실 가구, 현대적인 욕조 및 샤워 트레이, 세면기 및 설비, 훌륭한 액세서리를 찾을 수 있습니다. 귀하의 개인 웰빙 오아시스를 가구 및 장식하기 위한 아이디어를 제시해 드립니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/ideas/bathroom/overview">
                            욕실 아이디어 보러가기
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-img max-width="470" src="/images/ideas/bathroom/planner/planner-7.webp" class="mx-auto" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5">
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <h2 class="tit">욕실 컬렉션을 살펴보세요</h2>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col cols="12" lg="3" :key="item">
                            <v-card tile flat :href="item.link" target="_blank" max-width="400" class="mx-auto" color="transparent">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">{{ item.title }} 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import PlannerSlides from "@/components/client/ideas/bathroom/planner/planner-slides.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        PlannerSlides,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 플래너",
                href: "/ideas/bathroom/planner",
            },
        ],
        steps: [
            {
                header: "1단계: 개별 방 구조 만들기",
                content: '평면도: 우리가 미리 정의한 표준 방 모양을 기반으로 욕실 크기로 명확한 평면도를 만듭니다. 욕실이 지정된 표준 모양과 일치하지 않는 경우 "FREIER SPACE"를 사용하여 개별적으로 측정을 생성할 수 있습니다.',
            },
            {
                header: "2단계: 창문, 문 및 연결 추가",
                content: "구조적 요구 사항: 설계가 실제 조건과 일치하는지 확인하기 위해 두 번째 단계에서는 연결뿐만 아니라 기존 창문과 문도 추가하기만 하면 됩니다. 3D 욕실 플래너는 추가 계획 시 이러한 사항을 고려하므로 구현 중에 원치 않는 놀라움을 겪지 않고 욕실을 자유롭게 디자인할 수 있습니다.",
            },
            {
                header: "3단계: 이제 원하는 대로 욕실을 계획하세요.",
                content: "계획: 다양한 컬렉션에서 독특한 Villeroy & Boch 제품을 선택하고 평면도의 원하는 위치에 배치합니다. 구조적 요구 사항으로 인해 배치가 불가능한 경우 욕실 플래너가 즉시 알려드립니다. 다양한 보기와 관점 덕분에 개별 제품을 정확하게 조정하고 언제든지 선명한 3D 보기에서 디자인을 볼 수 있습니다.",
            },
            {
                header: "4단계: 욕실 계획을 귀하에게 보내드립니다.",
                content: "개인 욕실 계획: 욕실 계획을 성공적으로 완료한 후에는 디자인과 중요한 모든 제품 정보는 물론 기타 계획 옵션을 PDF 형식으로 받을 수 있습니다. 이렇게 하려면 제공된 필드에 이름, 성, 이메일 주소를 입력하기만 하면 됩니다. 잠시 후 꿈에 그리던 욕실을 만드는 데 필요한 모든 정보를 얻게 됩니다.",
            },
            {
                header: "5단계: 딜러로부터 조언 얻기",
                content: "딜러 검색: 가까운 전문 딜러를 찾으려면 해당 필드에 전화번호를 입력하고 목록에서 원하는 전문 딜러를 선택하세요. 그러면 그들은 귀하에게 연락하여 귀하의 욕실에 대한 향후 계획에 대해 유능하고 자문적인 지원을 제공할 것입니다.",
            },
        ],
        functions: [
            {
                header: "이상적인 계획의 자유",
                children: [
                    "치수가 포함된 평면도 욕실 계획",
                    "미리 정의된 방 모양을 선택하거나 개별적으로 생성하세요.",
                    "3D 뷰로 욕실 디자인 및 계획",
                    "구실, 물 및 전기 연결의 개념",
                    "나중에 욕실 계획 프로젝트의 추가 처리",
                    //
                ],
            },
            {
                header: "다양한 욕실 계획 옵션",
                children: [
                    "문, 창문, 커튼월 등 60개 이상의 객실 요소",
                    "개별 구성 요소의 색상 조정 및 구성",
                    "바닥, 벽, 전면 벽을 개별 타일로 마감할 수 있습니다.",
                    //
                ],
            },
            {
                header: "Villeroy & Boch의 다양성",
                children: [
                    "15개 이상의 Villeroy & Boch 컬렉션과 800개 이상의 Villeroy & Boch 제품으로 구성된 독특한 선택: 위생 도자기, 욕조, 샤워 트레이, 욕실 가구 및 타일",
                    "욕실 계획시 가구 색상 조정",
                    "특정 품목을 위한 서랍, 찬장 문 및 변기 열기",
                    "욕실 플래너 내에서 직접 제품 검색",
                    //
                ],
            },
            {
                header: "다양한 형식의 컨셉",
                children: ["평면도, 3D뷰, 품목목록을 이메일로 전송", "가상현실 뷰와 무료 VR 카드보드를 활용한 체험 계획"],
            },
        ],
        before: [
            {
                header: "구조적 특성에 주목하세요",
                content: "모든 욕실에는 특정 객실 계획에 중요한 구조적 특성이 있습니다. 물과 폐수 공급 라인이 이미 설치되어 있는 경우 세면기, 욕조 및 샤워기의 위치는 일반적으로 제한된 범위에서만 변경할 수 있습니다. 계획의 일환으로 Villeroy & Boch 전문 파트너에게 문의하여 연결 수와 필요한 확장 작업을 명확히 하세요. 경사진 천장은 욕실 디자인에 큰 영향을 미치는 제한 사항을 초래하므로 3D 욕실을 계획할 때 이 점을 고려해야 합니다. 개조할 때 선택한 제품이 나중에 기존 문에 맞는지 확인해야 합니다.",
            },
            {
                header: "옆방도 생각해보세요",
                content:
                    "요란한 샤워 소리, 미묘하게 졸졸 흐르는 목욕물 소리, 변기 물 내리는 소리 등 욕실의 소음은 완전히 피할 수 없습니다. 인접한 방에서 이러한 요소를 가능한 한 낮게 유지하려면 욕실의 개별 구성 요소를 신중하게 배치하는 것이 좋습니다. 가능한 한 위생 물체 사이의 직접적인 벽과 벽의 접촉을 피하는 것이 중요합니다. 샤워실과 변기를 외벽에 설치하면 소음 전달이 현저히 낮아집니다. 또한 계획에 욕실의 적절한 환기도 포함시키십시오. 창문이 없는 방에서는 환기 시스템을 설치해 습기와 냄새를 제거하는 것이 유리합니다.",
            },
            {
                header: "화장실의 이동 면적과 크기에 주의하세요",
                content:
                    "꿈에 그리던 욕실에서 완벽한 편안함을 느끼기 위해서는 충분한 움직임의 자유가 필요합니다. 특히 작은 욕실을 계획하고 있다면 방을 너무 꽉 차지 않도록 하세요. 위생 물체 사이의 최소 거리는 120 x 120 cm가 이상적입니다. 문이 없는 대형 샤워실을 선택하는 경우 물이 튀지 않도록 충분한 공간을 계획해야 합니다. 화장실을 분리하는 절반 높이 칸막이는 인테리어 디자인 디테일로 점점 인기를 얻고 있습니다. 필요한 경우 욕실을 디자인할 때 더 많은 공간을 계획하세요. 욕실 플래너를 사용하면 다양한 욕실 디자인 옵션을 미리 살펴볼 수 있습니다.",
            },
            {
                header: "당신의 욕실에는 무엇을 갖고 싶나요?",
                content: "당신만의 작은 웰니스 오아시스는 당신의 필요와 희망에 최대한 맞춰야 합니다. 그러므로 어떤 장비 요소가 당신에게 꼭 필요한지 미리 생각해 보세요. 목욕과 샤워를 하고 싶으신가요, 아니면 둘 중 하나로 충분할까요? 변기 외에 비데나 소변기를 설치해야 하나요? 욕실에 어떤 가전제품을 사용하고 싶나요? 욕실에 세탁기와 건조기를 설치하려면 물 연결부 옆에 콘센트가 충분한지 확인하세요. 적절한 전원 공급 장치도 조화로운 조명 개념에서 중요한 역할을 합니다.",
            },
        ],
        tips: [
            {
                header: "얼마나 많은 사람들이 화장실을 사용할 건가요?",
                content:
                    "가족 욕실에는 손님용 욕실과 다른 요구 사항이 있습니다. 그러므로 현재와 미래에 얼마나 많은 사람들이 화장실을 사용할지 생각해 보세요. 예를 들어, 아침에 두 가족이 동시에 양치질을 하고 싶다면 이중 세면기가 완벽한 솔루션입니다. 아이가 있다면 관리가 쉬운 소재가 장점이에요. 타일은 나무 벽 패널이나 벽지보다 청소가 훨씬 쉽고 빠릅니다. Villeroy & Boch CeramicPlus 마감재를 사용하면 세라믹 표면을 더욱 쉽게 청소할 수 있습니다. 욕실 가구를 선택할 때는 필요한 수납 공간도 고려해야 합니다.",
            },
            {
                header: "장벽 없는 욕실을 계획하고 싶으신가요?",
                content: "욕실을 재설계하거나 개조할 때 접근성 문제도 관련이 있습니다. 그에 따라 편안함을 최적화하려면 모든 관련 개체에 쉽게 접근할 수 있는 충분한 공간이 있어야 합니다. 예를 들어, 문지방이 없는 출입문과 대형 샤워실은 무장애 욕실의 필수 구성 요소입니다. 개별 설치 높이와 개별 가구 구성 요소의 확장된 기능도 고려해야 합니다. 욕조의 기립 보조 기구나 샤워 시트와 같은 실용적인 추가 제품의 경우 욕실 가구에 적절한 예방 조치를 취하는 것이 중요합니다.",
            },
            {
                header: "스타일 설정",
                content:
                    "욕실을 새롭게 디자인하면 완전히 새로운 스타일을 구현할 수 있는 기회가 제공됩니다. 따라서 계획을 시작하기 전에 어떤 디자인이 귀하의 바람과 아이디어에 가장 잘 조화되는지 고려해야 합니다. 시대를 초월하고 클래식해야 할까요, 아니면 화려하고 럭셔리해야 할까요? 컨트리 스타일과 대도시 스타일 중 어느 것을 선호하시나요? 명확한 선이 중요합니까, 아니면 곡선 모양을 좋아합니까? 영감 페이지에서 자유롭게 아이디어를 얻고 온라인 욕실 플래너의 객실 프로필에서 개별 구성 요소가 어떻게 작동하는지 시험해보세요.",
            },
            {
                header: "광학과 기능의 상호작용",
                content: "가구를 선택할 때 디자인뿐만 아니라 개별 가구 요소의 실용성에도 주의를 기울이세요. 모든 케어 제품을 질서정연하게 보관하려면 찬장, 보관 공간, 선반을 충분히 계획해야 합니다. 새로운 꿈의 욕실을 위한 공간이 많지 않다면 키가 큰 수납장과 화장대가 이상적입니다. 실용적인 가구는 진정한 수납 공간의 기적이며 공간을 거의 차지하지 않습니다. 충분히 큰 세면기는 일상적인 사용에도 편안함을 제공합니다.",
            },
            {
                header: "욕실을 적절한 조명에 두세요",
                content:
                    "꿈의 욕실을 효과적으로 조명하려면 다양한 광원을 조합하여 사용할 수 있습니다. 예를 들어 조정 가능한 매립형 스포트라이트를 사용하여 기본 밝기를 달성하는 동시에 목표 방식으로 개별 개체를 강조 표시할 수 있습니다. 거울 조명은 일상적인 관리와 웰빙 루틴에 필요한 밝기를 제공합니다. 램프를 선택할 때 차가운 빛과 따뜻한 빛의 다양한 효과를 고려해야 합니다. 유색 조명은 또한 대기 효과를 만들어냅니다. 예를 들어, 파란색은 편안한 효과를 주는 반면, 주황색 조명은 아늑한 따뜻함을 만들어줍니다.",
            },
        ],
        faqs: [
            {
                header: "온라인 욕실 플래너에서 개별 방 모양을 어떻게 만들 수 있나요?",
                content: '개별 방 모양의 경우 첫 번째 단계의 "여유 공간" 방 모양에서 "시작"을 선택합니다. 방을 자유롭게 그릴 수 있는 그리드가 나타납니다. 시작점을 클릭하고 원하는 방향으로 선을 끌어서 놓습니다. 선을 놓으면 벽이 생성됩니다. 시작점으로 돌아올 때까지 반복하세요.',
            },
            {
                header: "욕실에서 창문과 같은 공간 요소를 어떻게 계획하나요?",
                content: "“장비” 아래의 두 번째 단계 “방”에서 방 요소를 욕실에 추가할 수 있습니다. 이렇게 하려면 왼쪽 메뉴에서 카테고리를 클릭하고 방 요소를 선택하세요. 욕실의 원하는 위치에 끌어다 놓을 수 있습니다. 드래그 앤 드롭을 사용하여 언제든지 이미 배치된 요소를 다시 이동할 수도 있습니다.",
            },
            {
                header: "욕실에 벽을 어떻게 추가하나요?",
                content: '방의 모양을 결정한 후 "장비" 아래의 두 번째 단계인 "방"에서 욕실에 다양한 벽을 추가할 수 있습니다. 이렇게 하려면 왼쪽 메뉴에서 "벽"을 클릭하고 구실 중 하나를 선택하세요. 드래그 앤 드롭을 사용하여 욕실의 기존 벽에 배치할 수 있습니다. 이미 배치된 구실을 언제든지 선택하여 이동할 수 있습니다.',
            },
            {
                header: "욕실 바닥/벽의 색상/타일을 어떻게 변경하나요?",
                content: '바닥이나 벽은 물론 욕실의 다른 공간 요소를 다양한 색상과 타일로 디자인할 수 있습니다. 두 번째 단계인 "Room"에서는 왼쪽 메뉴의 "Colors"에서 원하는 룸 요소를 선택합니다. 또는 Room에서 원하는 요소를 간단히 선택할 수도 있습니다. 이제 사전 설정된 색상을 클릭하여 원하는 색상이나 Villeroy & Boch 타일을 선택할 수 있습니다.',
            },
            {
                header: "욕실 계획을 저장하고 나중에 계속하려면 어떻게 해야 하나요?",
                content: '언제든지 욕실 계획을 저장하고 네 번째 "완료" 단계에서 "계획 완료"를 클릭하여 나중에 계속할 수 있습니다. 문의 양식을 작성하면 개별 계획에 대한 링크와 평면도, 3D 보기 및 품목 부품 목록이 포함된 PDF가 전송됩니다. 이제부터 귀하의 계획은 영구적으로 저장되며 언제든지 액세스하고 편집할 수 있습니다.',
            },
        ],
        collections: [
            {
                title: "Subway 3.0",
                text: "트렌드를 뛰어넘어 지속되는 시대를 초월한 아름다운 디자인!",
                image: "/images/ideas/bathroom/planner/planner-collection-1.webp",
                link: "/",
            },
            {
                title: "Architectura",
                text: "모든 욕실에 전문적인 느낌을 더해보세요.",
                image: "/images/ideas/bathroom/planner/planner-collection-2.webp",
                link: "/",
            },
            {
                title: "Finion",
                text: "세련됨: 고품질 소재와 뛰어난 기술.",
                image: "/images/ideas/bathroom/planner/planner-collection-3.webp",
                link: "/",
            },
            {
                title: "Venticello",
                text: "세련된 디자인과 기능성을 갖춘 최신 트렌드의 우아함.",
                image: "/images/ideas/bathroom/planner/planner-collection-4.webp",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-expansion-panels {
    .v-expansion-panel {
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}
.function-content-list {
    list-style: disc;
    padding-left: 20px;
    > li {
        line-height: 1.4;
    }
}
@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
</style>