var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/renovation/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom Renovation"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "mt-50 mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("종합적인 욕실 바꾸기: 팁, 아이디어 및 가이드")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 새로 바꾸든, 새 집의 욕실 개조를 계획하고 있든, 욕실 바꾸기는 잘 계획해야 합니다. 물론, 욕실을 직접 바꾸는 것도 가능합니다.")])])], 1)], 1)])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/renovation/renovation.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Villeroy & Boch의 욕실 영감")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("욕실을 리노베이션할 때는 기본적으로 평면도와 방의 기존 연결에 구속됩니다. 그럼에도 불구하고 욕실을 리노베이션할 때 아이디어를 제공하여 개인 취향에 맞게 결과를 맞춤 설정할 수 있습니다. 또한 새로운 욕실 디자인에 대한 영감을 제공할 수도 있습니다!")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('dit', {
    staticClass: "tit"
  }, [_vm._v("욕조와 샤워")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕조와 샤워기는 많은 욕실의 핵심입니다. 욕실 개조의 일환으로 욕조와 샤워기를 변경하실 건가요?")])], 1), _c('renovation-shower-slide')], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("양변기 개조")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("때로는 양변기 개조가 필요할 수도 있습니다. 스타일 선호도나 디자인 요구 사항을 변경하는 것 외에도 리뉴얼에는 실용적인 이유가 있을 수 있습니다. 많은 사람들이 어느 시점에 바닥형 변기에서 벽부형 변기로 전환하기로 결정합니다. 어쩌면 샤워 화장실이 대안일까요?")])])], 1)], 1)], 1), _c('renovation-toilet-slide'), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("세면기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 사용하는 사람 수에 따라 세면기가 1개 또는 2개 필요할 수 있습니다. Villeroy & Boch는 빌트인형, 조리대형, 벽걸이형 세면기 등 다양한 제품을 제공합니다. 세면기를 좋아하는 수도꼭지와 결합해보세요.")])])], 1), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-12px mb-md-16px",
    attrs: {
      "src": "/images/ideas/bathroom/renovation/washbasin.webp"
    }
  }), _c('div', {
    staticClass: "tit tit--xs mb-6px mb-md-8px"
  }, [_vm._v("싱크대를 조립하세요")]), _c('div', {
    staticClass: "txt tit--xs mb-12px mb-md-16px"
  }, [_vm._v("최상의 시나리오에서는 기존 연결에 직접 새 싱크를 설치할 수 있습니다. 거리는 동일해야합니다. 설치 지침에서 Villeroy & Boch 세면기의 연결부 사이의 거리가 얼마나 큰지 확인할 수 있습니다.")]), _c('router-link', {
    staticClass: "collection-card__button d-flex align-center",
    attrs: {
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("싱크대 보러 가기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": _vm.hover ? '/images/icon/icon-arrow-right-active.svg' : '/images/icon/icon-arrow-right.svg'
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 my-40px my-md-80px py-40px py-md-80px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('ul', {
    staticClass: "cautions-list"
  }, [_vm._l(_vm.cautions, function (item) {
    return [_c('li', {
      key: item
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('v-col', [_c('div', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  })], 2)])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 개조")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실을 리모델링할 때, 시간이 얼마나 걸릴지에 대한 질문은 필연적으로 발생합니다. 욕실 개조 기간은 다양한 요인에 따라 달라집니다. 더 많은 거래를 수행할수록 혁신에 더 많은 시간이 소요됩니다. 욕실을 직접 개조하면 시간을 절약할 수 있습니다. "), _c('br'), _vm._v(" 욕실을 개조할 때 비용이 얼마나 발생하는지도 스스로 얼마나 하느냐에 따라 달라집니다. 새 샤워실의 재료비는 500~1,500유로이고, 변기와 세면기 비용은 각각 100~500유로입니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('renovation-bathroom-slide')], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("수리 및 폐기")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("실제 욕실 개조 외에도 손상된 부분이 있는 경우 적시에 수리하고 오래된 물건을 올바르게 폐기해야 합니다. 특히 욕실에서는 배수구 막힘, 설비 불량, 물 손상 등이 수시로 발생할 수 있습니다.")]), _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.announcements, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }