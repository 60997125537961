<template>
    <swiper class="swiper" :options="options">
        <template v-for="image in images">
            <swiper-slide :key="image">
                <v-card tile flat :img="image">
                    <v-responsive :aspect-ratio="4 / 3" class="d-md-none" />
                    <v-responsive :aspect-ratio="5 / 2" class="d-none d-md-block" />
                </v-card>
            </swiper-slide>
        </template>
        <div slot="pagination">
            <div class="swiper-button-prev">
                <v-icon>mdi-chevron-left</v-icon>
            </div>
            <div class="swiper-button-next">
                <v-icon>mdi-chevron-right</v-icon>
            </div>
        </div>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            centeredSlides: false,
            speed: 500,
            loop: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
        },

        images: [
            "/images/ideas/bathroom/trends/trend-slide-1.webp",
            "/images/ideas/bathroom/trends/trend-slide-2.webp",
            "/images/ideas/bathroom/trends/trend-slide-3.webp",
            "/images/ideas/bathroom/trends/trend-slide-4.webp",
            "/images/ideas/bathroom/trends/trend-slide-5.webp",
            "/images/ideas/bathroom/trends/trend-slide-6.webp",
            "/images/ideas/bathroom/trends/trend-slide-7.webp",
            //
        ],
    }),
};
</script>