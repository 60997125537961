<template>
    <div>
        <swiper v-bind="{ options }">
            <template v-for="item in items">
                <swiper-slide :key="item">
                    <v-row align="center" class="row--lg">
                        <v-col cols="12" md="5" order="2" order-md="1">
                            <v-card color="white" class="h-md-400px d-md-flex align-center">
                                <div>
                                    <div class="tit mb-12px mb-md-16px">
                                        {{ item.title }}
                                    </div>
                                    <p class="txt txt--sm mb-18px mb-md-24px">
                                        {{ item.text, }}
                                    </p>
                                    <template v-if="item.button">
                                        <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                            {{ item.button }}
                                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                        </v-btn>
                                    </template>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="7" order="1" order-md="2">
                            <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                <v-responsive :aspect-ratio="674 / 504" />
                                <div class="swiper-controler">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <div class="swiper-pagination" />
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </swiper-slide>
            </template>
        </swiper>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                title: "욕실 교체",
                text: "욕실을 개조할 때는 매우 조심스럽게 작업해야 합니다. 거치식 변기든 벽걸이 변기든 설치 시 안정성에 유의하여 사고를 예방하세요.",
                image: "/images/ideas/bathroom/renovation/shower-1.webp",
            },
            {
                title: "샤워실 밀봉하기",
                text: "실리콘은 일반적으로 샤워기나 욕조를 밀봉하기 위해 밀봉 테이프와 함께 사용됩니다. 이는 곰팡이 형성을 방지합니다. 따라서 대형 샤워실을 설치할 때 밀봉이 잘 되는지 확인하는 것이 더욱 중요합니다.",
                image: "/images/ideas/bathroom/renovation/shower-2.webp",
                button: "샤워 트레이 알아보기",
                link: "/",
            },
            {
                title: "샤워 설비 설치",
                text: "Artis 세면기와 Collaro 베이스 캐비닛을 갖춘 세련된 게스트 욕실입니다. 작은 공간을 위한 영감을 주는 솔루션.",
                image: "/images/ideas/bathroom/renovation/shower-3.webp",
                button: "샤워 설비 알아보기",
                link: "/",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    .swiper-container {
        overflow: visible;
    }
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
</style>