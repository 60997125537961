<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/renovation/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom Renovation" />

        <page-section class="py-40px py-md-80px">
            <v-container>
                <div class="mt-50 mt-md-100px">
                    <v-row class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit mb-12px mb-md-16px">종합적인 욕실 바꾸기: 팁, 아이디어 및 가이드</div>
                            <p class="txt txt--sm">욕실을 새로 바꾸든, 새 집의 욕실 개조를 계획하고 있든, 욕실 바꾸기는 잘 계획해야 합니다. 물론, 욕실을 직접 바꾸는 것도 가능합니다.</p>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--xxl">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/renovation/renovation.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">Villeroy & Boch의 욕실 영감</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">욕실을 리노베이션할 때는 기본적으로 평면도와 방의 기존 연결에 구속됩니다. 그럼에도 불구하고 욕실을 리노베이션할 때 아이디어를 제공하여 개인 취향에 맞게 결과를 맞춤 설정할 수 있습니다. 또한 새로운 욕실 디자인에 대한 영감을 제공할 수도 있습니다!</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <dit class="tit">욕조와 샤워</dit>
                    <p class="txt txt--sm">욕조와 샤워기는 많은 욕실의 핵심입니다. 욕실 개조의 일환으로 욕조와 샤워기를 변경하실 건가요?</p>
                </div>

                <renovation-shower-slide />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">양변기 개조</div>
                        <p class="txt txt--sm">때로는 양변기 개조가 필요할 수도 있습니다. 스타일 선호도나 디자인 요구 사항을 변경하는 것 외에도 리뉴얼에는 실용적인 이유가 있을 수 있습니다. 많은 사람들이 어느 시점에 바닥형 변기에서 벽부형 변기로 전환하기로 결정합니다. 어쩌면 샤워 화장실이 대안일까요?</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <renovation-toilet-slide />

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">세면기</div>
                        <p class="txt txt--sm">욕실을 사용하는 사람 수에 따라 세면기가 1개 또는 2개 필요할 수 있습니다. Villeroy & Boch는 빌트인형, 조리대형, 벽걸이형 세면기 등 다양한 제품을 제공합니다. 세면기를 좋아하는 수도꼭지와 결합해보세요.</p>
                    </v-col>
                </v-row>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <v-card tile flat>
                            <v-img src="/images/ideas/bathroom/renovation/washbasin.webp" class="mb-12px mb-md-16px" />
                            <div class="tit tit--xs mb-6px mb-md-8px">싱크대를 조립하세요</div>
                            <div class="txt tit--xs mb-12px mb-md-16px">최상의 시나리오에서는 기존 연결에 직접 새 싱크를 설치할 수 있습니다. 거리는 동일해야합니다. 설치 지침에서 Villeroy & Boch 세면기의 연결부 사이의 거리가 얼마나 큰지 확인할 수 있습니다.</div>
                            <router-link to="/" class="collection-card__button d-flex align-center">
                                <span class="txt txt--xs txt--dark font-weight-medium">싱크대 보러 가기</span>
                                <v-img max-width="24" :src="hover ? '/images/icon/icon-arrow-right-active.svg' : '/images/icon/icon-arrow-right.svg'" class="ml-8px" />
                            </router-link>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 my-40px my-md-80px py-40px py-md-80px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <ul class="cautions-list">
                            <template v-for="item in cautions">
                                <li :key="item">
                                    <v-row class="row--xxs">
                                        <v-col cols="12">
                                            <div class="txt txt--sm txt--dark font-weight-medium">
                                                {{ item.title }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm">
                                                {{ item.text }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </li>
                            </template>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">욕실 개조</div>
                        <p class="txt txt--sm">
                            욕실을 리모델링할 때, 시간이 얼마나 걸릴지에 대한 질문은 필연적으로 발생합니다. 욕실 개조 기간은 다양한 요인에 따라 달라집니다. 더 많은 거래를 수행할수록 혁신에 더 많은 시간이 소요됩니다. 욕실을 직접 개조하면 시간을 절약할 수 있습니다. <br />
                            욕실을 개조할 때 비용이 얼마나 발생하는지도 스스로 얼마나 하느냐에 따라 달라집니다. 새 샤워실의 재료비는 500~1,500유로이고, 변기와 세면기 비용은 각각 100~500유로입니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <renovation-bathroom-slide />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">수리 및 폐기</div>
                        <div class="txt txt--sm">실제 욕실 개조 외에도 손상된 부분이 있는 경우 적시에 수리하고 오래된 물건을 올바르게 폐기해야 합니다. 특히 욕실에서는 배수구 막힘, 설비 불량, 물 손상 등이 수시로 발생할 수 있습니다.</div>
                        <v-expansion-panels tile>
                            <template v-for="item in announcements">
                                <v-expansion-panel :key="item">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import RenovationShowerSlide from "@/components/client/ideas/bathroom/renovation/renovation-shower-slide.vue";
import RenovationToiletSlide from "@/components/client/ideas/bathroom/renovation/renovation-toilet-slide.vue";
import RenovationBathroomSlide from "@/components/client/ideas/bathroom/renovation/renovation-bathroom-slide.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        RenovationShowerSlide,
        RenovationToiletSlide,
        RenovationBathroomSlide,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 바꾸기",
                href: "/ideas/bathroom/",
            },
        ],
        cautions: [
            {
                title: "사이펀을 설치하고 제대로 밀봉하십시오.",
                text: "벽에 설치한 후에도 새 세면기의 사이펀을 설치하고 밀봉해야 합니다. 물이 새지 않도록 고무 씰을 사용하십시오.",
            },
            {
                title: "오래된 싱크대를 올바르게 폐기하십시오.",
                text: "오래된 욕실을 개조하는 경우 나중에 위생 시설을 폐기해야 합니다. 이는 주로 사용되는 재료에 따라 달라집니다. 재활용 센터에 클래식 에나멜 또는 금속 싱크대를 반납할 수 있습니다.",
            },
        ],
        announcements: [
            {
                header: "물로 인한 피해 처리",
                content: '물로 인한 피해가 발생하면 적시에 인식하는 것이 필수적입니다. 벽에 이미 물 얼룩이 보인다면 완전히 뜯어내야 할 수도 있습니다. 어떤 경우든 전문 회사에 의뢰하여 누수를 찾아 제거해야 합니다.',
            },
            {
                header: "수도꼭지 폐기",
                content: "새 수도꼭지를 사기로 결정했다면 오래된 수도꼭지를 분해하여 올바르게 폐기해야 합니다. 피팅과 같은 작은 금속 부품은 재활용 쓰레기통에 간단히 폐기할 수 있습니다.",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.color-image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
    .color-text {
        font-size: 12px;
        line-height: 1.8;
        margin-top: 40px;
    }
}
.cautions-list {
    > li {
        padding-left: 20px;
        margin-bottom: 12px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 4px;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
    }
}
.v-expansion-panels {
    .v-expansion-panel {
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}
@media (min-width: 768px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
    .cautions-list {
        > li {
            margin-bottom: 16px;
            &::before {
                top: 10px;
            }
        }
    }
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
        .color-text {
            position: absolute;
            bottom: 100px;
            margin: initial;
        }
    }
}
</style>